.application-form {
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.application-form form {
    display: flex;
    flex-direction: column;
}

.application-form fieldset {
    border: 1px solid #ddd;
    padding: 10px;
    margin-bottom: 20px;
}

.application-form legend {
    padding: 0 5px;
    width: auto;
}

.application-form label {
    margin-top: 10px;
    font-weight: bold;
}

.application-form input[type="text"],
.application-form input[type="email"],
.application-form input[type="tel"] {
    width: 95%;
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.submit-button {
    background-color: var(--brand-orange); /* Green */
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: all 0.2s ease-in-out; /* Smooth hover effect */
}

.submit-button:hover {
    background-color: var(--brand-blue);
}

.submission-message {
    text-align: center;
    padding: 20px;
    background-color: #dff0d8;
    color: #3c763d;
    border-radius: 8px;
    margin: 20px auto;
    max-width: 600px;
}
