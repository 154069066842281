.locations-page {
    color: var(--brand-blue);
    padding: 20px;
    max-width: 1200px;
    margin: auto;
}

.locations-page h1 {
    color: var(--brand-orange);
    text-align: center;
}

.locations-page p {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 20px;
}

.map-container {
    position: relative;
    text-align: center;
    margin-top: 20px;
}

.map-image {
    width: 100%;
    max-width: 100%;
    height: auto;
    filter: blur(4px); /* Adjust blur intensity as needed */
}

.coming-soon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255, 0.75); /* Semi-transparent white */
    color: var(--brand-blue);
    padding: 10px 20px;
    font-size: 24px;
    font-weight: bold;
    border: 3px solid var(--brand-orange);
    border-radius: 8px;
}
