:root {
    --brand-orange: #dda136; /* Define your color here */
    --brand-blue: #10133e; /* Define your color here */
    --brand-bg: #d7c7a0; /* Define your color here */
}

/* Basic Styles */
body {
    margin: 0;
    padding: 0;
    /*background-image: url('../assets/ENVELOPE/MAZUMA ENVELOPE-01.jpg'); !* Adjust path as needed *!*/
    background-position: top center; /* Align the top of the image with the top of the viewport */
    background-size: 100% auto; /* Ensure the image covers the full page */
    background-attachment: scroll; /* Optional: This will keep the image stationary on scroll */
    min-height: 100vh; /* Ensure it covers at least the full viewport height */
    background-repeat: no-repeat; /* Prevent the image from repeating */
    background-color: var(--brand-bg);
}


.App {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    /* Center content horizontally and add padding */
    justify-content: center;
    padding: 0;
}


/* Header Styles */
.header {
    display: flex;
    flex-direction: column; /* Change to column to stack logo and nav */
    justify-content: space-between;
    align-items: center;
    background-color: transparent; /* Ensure header is transparent */
    background-image: url('../assets/BANNER/MAZUMA BANNER.jpg'); /* Adjust path as needed */
    background-size: cover; /* Cover the header area */
    background-position: center;
    width: 100%; /* Ensure it covers the full width */
    height: 250px;
    position: relative;
}

/* New class for the navigation container */
.nav-container {
    background-color: #fff; /* White background */
    padding: 0.5rem 0; /* Reduced padding for aesthetic, adjust as needed */
    border-radius: 5px; /* Optional rounded corners */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional shadow for depth */
    margin-top: -1rem; /* Adjust based on your layout to attach it directly under the header */
    width: inherit; /* Match parent width */
    display: flex;
    justify-content: center;
    align-items: center; /* This centers the navigation links vertically */
    height: 65px;
    position: relative; /* Add this to make sure the menu-toggle can be positioned absolutely within */
    box-sizing: border-box; /* This ensures padding does not affect the actual box size */
}

nav {
    width: 100%;
    /*max-width: 1200px; !* Adjust this to control the max width of your nav *!*/
    display: flex;
    justify-content: center;
    align-items: center;
}

nav ul {
    display: flex;
    justify-content: center; /* Center the navigation items */
    align-items: center; /* Vertically align items */
    flex-wrap: wrap; /* Allow wrapping on smaller screens */
    padding: 0;
    margin: 0; /* Reset margins */
}

nav li {
    display: inline-flex; /* Use inline-flex to keep list items inline but also apply flex properties */
    align-items: center; /* Align items inside each li if you have icons or other elements */
    margin: 0 1rem; /* Adjust spacing around navigation items */
}

nav a {
    text-decoration: none;
    font-weight: bold; /* Make text bold */
    color: var(--brand-blue); /* Dark blue text */
}

/* Section Styles */
main {
    padding: 2rem 10rem;
}

section {
    padding: 2rem;
    margin-bottom: 2rem;
    text-align: center; /* Center text within sections */
    border-radius: 5px; /* Rounded corners */

    /* Alternate background colors */
    background-color: var(--brand-orange); /* Orange */
}

h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
}

section:nth-child(even) {
    background-color: var(--brand-blue); /* Dark blue */
}

section:nth-child(even) a {
    color: #ffa307; /* Dark blue */
}

section a {
    color: #3f48ff; /* Dark blue */
}

section:nth-child(even) h2,
section:nth-child(even) p,
section:nth-child(even) h3,
section:nth-child(even) li {
    color: var(--brand-orange); /* Orange */
}

.how-to-use ol,
.contact ul,
.support ul {
    list-style-position: inside; /* Moves the markers inside the content flow */
    text-align: center; /* Centers the text and markers */
    padding: 0; /* Removes default padding */
    margin: 0 auto; /* Centers the list if it's a block */
    display: inline-block; /* Or 'block', depending on your layout */
}

.how-to-use li,
.contact li,
.support li {
    text-align: left; /* Aligns the text to the left within the centered list */
    display: list-item; /* Ensures list item behavior */
}

.what-is-bitcoin-atm {
    display: flex;
    align-items: center; /* Keeps items vertically centered */
    text-align: left;
}

.atm-image {
    flex: 0 1 15%; /* Now allows the image to shrink but limits its basis to 20% of the container */
    max-width: 15%; /* Further reduces the max-width to 20% for a smaller image */
    height: auto; /* Maintains the aspect ratio */
    margin-right: 20px; /* Keeps space between the image and the text */
}

.what-is-bitcoin-atm > div {
    flex: 0 1 80%; /* Adjusts the text content to take up the remaining larger portion */
}

.about {
    display: flex;
    align-items: center; /* Aligns items vertically in the center */
    text-align: left;
}

.about-image {
    flex: 0 1 20%; /* Allows the image to shrink but limits its basis to 20% of the container */
    max-width: 20%; /* Further reduces the max-width to 20% for a smaller image */
    height: auto; /* Maintains the aspect ratio */
    margin-right: 20px; /* Adds some space between the image and the text */
}

.about > div {
    flex: 0 1 80%; /* Adjusts the text content to take up the remaining larger portion */
}


.cta-button {
    background-color: var(--brand-orange);
    color: #fff; /* White text */
    padding: 1rem 2rem;
    border: none;
    border-radius: 5px;
    font-size: 1.1rem;
    cursor: pointer;
    text-align: center; /* Center text within button */
    display: inline-block; /* Allow multiple buttons on a line */
    margin: 1rem auto; /* Center the button horizontally */
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    transition: all 0.2s ease-in-out; /* Smooth hover effect */
}

.cta-button:hover {
    background-color: var(--brand-blue); /* Dark blue on hover */
    transform: translateY(-2px); /* Slight button lift on hover */
}

.benefits {
    margin-bottom: 2rem;
    text-align: center;
    color: var(--brand-blue);
    display: flex; /* Make benefits section a flex container */
    flex-direction: column; /* Stack elements vertically */
    align-items: center; /* Center elements horizontally */
    justify-content: center; /* Center elements vertically */
    /*padding: 2rem 4rem 2rem 2rem;*/
    padding: 2rem;
}

.benefits h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
    color: inherit; /* Inherit white color from parent */
}

.benefits ul {
    list-style: none;
    padding: 0;
    display: flex; /* Arrange benefits in a row */
    flex-wrap: wrap; /* Allow items to wrap to multiple lines */
    justify-content: center; /* Center benefits horizontally */
    margin: 2rem 0 0; /* Adjust margin-top to avoid overlapping the image */
}

.benefits li {
    margin: 1rem;
    width: calc(33% - 2rem); /* Calculate width based on 3 columns */
    text-align: left; /* Align text to the left */
    /* Add subtle box-shadow for depth (optional) */
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
    padding: 1rem;
    border-radius: 5px; /* Rounded corners */
    background-color: var(--brand-blue);
    color: var(--brand-orange);
}

.benefits li img {
    width: 50px; /* Adjust image size as needed */
    margin-bottom: 1rem;
}


/* Ensure the FAQ section has a unique background */
.faq {
    background-color: var(--brand-orange);
    padding: 20px;
    text-align: center;
}

/* Style the FAQ items specifically */
.faq .faq-item {
    width: 75%;
    margin: 20px auto;
    background-color: var(--brand-orange);
    color: var(--brand-blue);
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.faq .faq-item:hover {
    background-color: #fff;
    color: var(--brand-blue);
}

/* Adjust the question color and include arrow styles */
.faq .faq-item h3 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--brand-blue); /* Make questions blue */
}

/* Specific to when the item is hovered */
.faq .faq-item:hover h3 {
    color: inherit; /* Keeps the question color consistent on hover */
}

/* Style for the arrow, adjusting based on FAQ open state */
.faq .faq-item .arrow {
    font-size: 1.5rem; /* Adjust size as needed */
    text-align: right;
}

/* Style for the FAQ answer text */
.faq .faq-item p {
    text-align: left;
    margin-top: 10px;
    color: var(--brand-blue); /* Ensures the answer text is always blue */
}

/* Adding an override for the hover state of the FAQ item to ensure text visibility */
.faq .faq-item:hover p {
    color: var(--brand-blue);
}


.menu-toggle {
    display: none; /* Hide by default */
    position: absolute;
    bottom: 10px;
    right: 30px;
    z-index: 100;
    background: none; /* Removes any background */
    border: none; /* Removes the border */
    color: #10133e; /* Sets the color to a shade of blue; adjust the color code as needed */
    font-size: 40px; /* Adjusts the size of the hamburger icon; change as needed */
    cursor: pointer; /* Changes cursor to pointer to indicate it's clickable */
}

.nav-links {
    display: flex; /* Default layout for larger screens */
    align-items: center; /* Center items vertically */
    justify-content: center; /* Center items horizontally */
    padding-left: 0; /* Remove default padding */
}

.nav-links-mobile {
    display: none; /* Initially hidden on mobile */
}

@media (max-width: 768px) {
    .what-is-bitcoin-atm {
        flex-direction: column;
    }

    .atm-image {
        max-width: 40%; /* Adjusts for a relatively larger, but still small, image on smaller screens */
        margin-right: 0;
        margin-bottom: 20px; /* Adds space between the now stacked image and text */
        align-self: center; /* Centers the image above the text */
    }

    .about {
        flex-direction: column;
    }

    .about-image {
        max-width: 100%; /* Adjusts for a relatively larger, but still small, image on smaller screens */
        max-height: 400px;
        margin-right: 0;
        margin-bottom: 20px; /* Adds space between the now stacked image and text */
        align-self: center; /* Centers the image above the text */
    }

    .benefits {
        padding: 2rem 4rem 2rem 2rem;
    }

    .nav-container {
        flex-direction: column;
    }

    nav ul {
        flex-direction: column;
        align-items: center;
    }

    nav li {
        margin: 0.5rem 0; /* Adjusted for spacing in a column layout */
    }

    main {
        padding: 1rem; /* Smaller padding for smaller screens */
    }

    .benefits ul {
        flex-direction: column; /* Stack benefits vertically on smaller screens */
    }

    .benefits li {
        width: 100%; /* Benefits take full width on smaller screens */
        margin: 1rem 0; /* Adjusted margin for vertical stacking */
    }

    .about, .what-is-bitcoin-atm {
        flex-direction: column;
    }

    .about-image, .atm-image {
        max-width: 80%; /* Larger percentage for smaller screens */
        margin: 0 auto 20px; /* Center images with auto margins and add spacing */
    }

    .header {
        height: auto; /* Allow the header to adjust to content size */
        padding: 20px 10px; /* Add some padding */
    }

    .nav-container {
        padding: 0.5rem; /* Reduced padding */
    }

    .nav-container, nav ul, .benefits ul {
        flex-direction: column;
    }

    section {
        padding: 1rem;
    }

    h2 {
        font-size: 1.5rem; /* Smaller headers on very small screens */
    }

    .menu-toggle {
        display: block; /* Show the toggle button on mobile */
    }

    .nav-container {
        display: none;
    }

    .nav-container.expanded {
        height: auto; /* Allow the container to expand */
        align-items: flex-start; /* Align items to the start */
        display: flex;
    }

    .nav-links {
        display: none; /* Hide the horizontal menu on mobile */
    }

    .nav-links-mobile, .nav-container.expanded .nav-links {
        display: flex; /* Display the menu in flex mode */
        flex-direction: column; /* Stack items vertically */
        width: 100%; /* Full width */
        align-items: center; /* Center items horizontally */
    }

    nav ul {
        width: 100%; /* Full width for the nav element */
    }

    nav li {
        width: 100%; /* Full width for list items */
        text-align: center; /* Center text */
    }

    nav a {
        display: block; /* Make links block to fill their container */
        width: 100%; /* Full width for links */
        padding: 10px 0; /* Padding for tap targets */
    }

    .header {
        height: 100px; /* Allow the header to adjust to content size */
    }

    .cta-button {
        width: 50%;
    }

    .faq .faq-item {
        width: 90%; /* Increase width for smaller screens */
    }
}



@media (min-width: 769px) {
    .nav-container {
        display: flex !important; /* Override to ensure visibility on wider screens */
        visibility: visible !important;
        max-height: none !important; /* If you're animating max-height */
    }
    /* Hide the menu toggle button on wider screens */
    .menu-toggle {
        display: none;
    }

    .nav-links {
        display: block; /* Hide the horizontal menu on mobile */
    }
}